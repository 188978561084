<template>
  <div>
    <v-row class="header align-center">
      <router-link class="router-link d-block pa-0" to="/" rel="prefetch">
        <span class="logo py-2">
          <img :src="googleAdsLogo" alt="PivotAd Logo" width="165" height="50" loading="lazy"/>
        </span>
      </router-link>
      <v-spacer></v-spacer>
      <router-link class="router-link" to="/features" rel="prefetch">
        <span :class="getRouteName === 'Features' ? 'border-bottom' : ''">
          {{ $t("unauthorized_header.features") }}
        </span>
      </router-link>
      <router-link class="router-link" to="/pricing" rel="prefetch">
        <span :class="getRouteName === 'Pricing' ? 'border-bottom' : ''">
          {{ $t("unauthorized_header.pricing") }}
        </span>
      </router-link>
      <router-link class="router-link" to="/services" rel="prefetch">
        <span :class="getRouteName === 'Services' ? 'border-bottom' : ''">
          {{ $t("unauthorized_header.services") }}
        </span>
      </router-link>
      <router-link class="router-link" to="/company" rel="prefetch">
        <span :class="getRouteName === 'Company' ? 'border-bottom' : ''">
          {{ $t("unauthorized_header.company") }}
        </span>
      </router-link>
      <router-link class="router-link" to="/contact-us" rel="prefetch">
        <span :class="getRouteName === 'ContactUs' ? 'border-bottom' : ''">
          {{ $t("unauthorized_header.contacts") }}
        </span>
      </router-link>
      <router-link class="router-link" to="/blog" rel="prefetch">
        <span
          :class="
            getRouteName === 'Blog' || getRouteName === 'BlogDetail'
              ? 'border-bottom'
              : ''
          "
          >{{ $t("unauthorized_header.blog") }}</span
        >
      </router-link>
      <v-btn
        class="login-btn px-5"
        color="#fff"
        outlined
        @click="loginModal = true"
      >
        {{ $t("unauthorized_header.login") }}
        <v-icon right> mdi-login-variant</v-icon>
      </v-btn>
      <v-btn
        class="register-btn px-6"
        color="#fff"
        outlined
        @click="openRegistrationModal"
      >
        {{ $t("unauthorized_header.register") }}
      </v-btn>
      <Login
        :dialog="loginModal"
        @closeModal="loginModal = false"
        @openSignUp="registerModal = true"
      />
      <Registration
        :dialog="registerModal"
        @closeModal="registerModal = false"
        @openSignIn="loginModal = true"
      />
      <v-btn class="menu-icon ml-2" icon @click="setDrawer(true)">
        <v-icon color="#fff" large> mdi-menu</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import googleAdsLogo from "../../assets/icon/Pivotadd_logo.svg";
export default {
  name: "Header",
  components: {
    Registration: () => import("@/components/MainLanding/Modals/Registration"),
    Login: () => import("@/components/MainLanding/Modals/Login"),
  },
  data: () => ({
    googleAdsLogo,
    loginModal: false,
    registerModal: false,
  }),
  computed: {
    getRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({ setDrawer: "setDrawer" }),
    openRegistrationModal() {
      localStorage.clear();
      this.registerModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 35px 15px 50px 15px;
  @media screen and (max-width: 960px) {
    padding: 15px 15px;
  }
}

.menu-icon {
  display: none;
  @media screen and (max-width: 780px) {
    display: block;
  }
}

.logo {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  font-style: normal;
  line-height: 35px;
}

.login-btn {
  border-radius: 16px;
  height: 48px !important;
  text-transform: none;
  font-size: 17px;
  margin: 0 20px;
  font-weight: 400;
  @media screen and (max-width: 960px) {
    margin: 0 5px;
  }
  @media screen and (max-width: 780px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 780px) {
    display: none;
  }
}

.register-btn {
  border-radius: 16px;
  height: 48px !important;
  text-transform: none;
  background-color: #0057ff;
  border: none;
  font-weight: 400;
  font-size: 17px;
  @media screen and (max-width: 780px) {
    display: none;
  }
}

.router-link {
  text-decoration: none;
  padding: 0 20px;
  color: #ffffff;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 2px;
  @media screen and (max-width: 1270px) {
    padding: 0 10px;
  }
  @media screen and (max-width: 960px) {
    padding: 0 7px;
  }
  @media screen and (max-width: 780px) {
    display: none;
  }
}
</style>
